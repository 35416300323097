import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';


class WorkExperience extends React.Component{
    render(){
        var company = this.props.company;
        var position = this.props.positionn;
        var description = '';
        var location = this.props.location;
        var dates = this.props.dates;

        console.log(this.props.description);
        if(this.props.description){
            description = this.props.description;
            
            console.log(description)
        }
            
        return(
            <div className="experience-item">
                <div className="experience-title-container">
                    <p> { company } - { location }</p>
                    <p> { position } </p>
                    <p>{ dates }</p>
                </div>

                <div className="experience-content">
                    
                </div>
            </div>
        );
    }
}

export default WorkExperience;