import React from 'react'
import HomeTitle from '../components/home-title'
import CallToAction from '../components/call-to-action'

export default () => (
    <section id="section-home" className="home-section">
        <div className="lg-wrap">
            <HomeTitle/>
        </div>

        {/* Create component here */}
        <CallToAction link="#about" message="Let's Work Together"/>
    </section>
)
