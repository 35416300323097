import React from 'react'
import get from 'lodash/get'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronDown } from '@fortawesome/free-solid-svg-icons'

class CallToAction extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        var msg = this.props.message;
        var link = this.props.link;
        return(
            <div className="call-to-action">
                <a href={ link }>{ msg }<br/><FontAwesomeIcon icon={ faChevronDown }/></a>
            </div>
        );

    }
}

export default CallToAction