import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import ReactMarkdown from 'react-markdown'

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import TabContainer from 'react-bootstrap/TabContainer'
import TabContent from 'react-bootstrap/TabContent'
import TabPane from 'react-bootstrap/TabPane'

import { Link } from 'gatsby'

import Nav from 'react-bootstrap/Nav'

import AboutBio from './about-bio';
import AboutSkillsCat from './about-skills-cat'
import WorkExperience from './about-experience'

export default () => {
  const data = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    allContentfulSkills(sort: {fields: id, order: ASC}) {
      edges {
        node {
          id
          title
          skills
        }
      }
    }
    allContentfulPerson {
      edges {
        node {
          name
          shortBio {
            shortBio
            childMarkdownRemark {
              rawMarkdownBody
              html
              htmlAst
            }
          }
          title
          github
          email
          image {
            file {
              url
            }
          }
        }
      }
    }
    allDirectory {
      edges {
        node {
          id
        }
      }
    }
    allContentfulWorkExperience(sort: {fields: startDate, order: DESC}) {
      edges {
        node {
          id
          lengthOfTerm
          company
          position
          location
          achievements {
            content {
              content {
                value
              }
            }
          }
        }
      }
    }
  }
  `)

  return (
    <section id="#section-about" className="about-section">
        <div className="med-wrap content-container">
            <Container fluid>
                <Row>
                    <Col lg={8} sm={12}>
                      <TabContainer defaultActiveKey="about-bio">
                        <div className="content-heading-container">
                          <h3 className="title">About Me</h3>
                          <div className="about-navigation">

                            <Nav>
                              <Nav.Item className="navigationItem">
                                <Nav.Link eventKey="about-bio" href="#">Bio</Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="navigationItem">
                                <Nav.Link eventKey="about-skills" href="#">Skills</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </div>
                        <div className="tab-content">
                          
                            <TabContent id="about-bio">
                                {data.allContentfulPerson.edges.map(({ node }, i) => (
                                  <TabPane eventKey="about-bio">
                                    <AboutBio>
                                      <ReactMarkdown>{ node.shortBio.childMarkdownRemark.rawMarkdownBody }</ReactMarkdown>
                                    </AboutBio>
                                  </TabPane>
                                ))}
                                {data.allContentfulSkills.edges.map(({ node }, i) => (
                                  <TabPane eventKey="about-skills">
                                    <div className="about-skills-container">
                                        <AboutSkillsCat catName={ node.title } skillSet={ node.skills } skillCatId={ node.title }/>
                                    </div>
                                  </TabPane>
                                ))}
                            </TabContent>
                          
                        </div>
                      </TabContainer>
                    </Col>
                    <Col lg={4} sm={12}>
                        {data.allContentfulPerson.edges.map(({ node }, i) => (
                            <div className="image-container">
                                <img src={node.image.file.url} />
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
  )
}