import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


class AboutSkillsCat extends React.Component{
    render(){
        var id = this.props.skillCatId;
        var name = this.props.catName;
        var skillSet = this.props.skillSet;
        return(
            <div id={ id } className="about-skills-cat">
                <h6 className="skills-category"> { name } </h6>
                <div class="skills-list">
                    <ul>
                        {skillSet.map(skill => (
                            <li><span>{ skill }</span></li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default AboutSkillsCat;