import React from 'react'
import { Link } from 'gatsby'

class AboutBio extends React.Component{
    render(){
        var id = this.props.tabId;
        var content = this.props.bioContent;
        return(
            <div id={ id } className="bio">
                { this.props.children }
            </div>
        );
    }
}

export default AboutBio;