import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/index.scss"

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

import HomeSection from '../components/home'
import About from '../components/about';


class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle}/>
        <HomeSection/>
        <About/>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        description
        title
      }
    }
    allContentfulSkills {
      edges {
        node {
          id
          title
          children {
            id
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "5C2vrsH0jPqeRp80FsB9yt" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
