import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
export default () => (
    <div className="home-title">
        <h1>
            <span>Hey,</span><br/>
            <span>I'm Stephen Noble.</span><br/>
            <span>I'm a Web Developer</span><br/>
        </h1>
    </div>
)